import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import styles from "./homeTabs.module.scss";
import Tabs from "../../Tabs";
import ImgWithText from "../../ImgWithText";
import drawingsIcon from '../../../images/drawings-icon.svg';
import collaborationIcon from '../../../images/collaboration-icon.svg';
import timetrackingIcon from '../../../images/timetracking-icon.svg';
import projectReportsIcon from '../../../images/project-reports-icon.svg';

const HomeTabs = () => (
  <StaticQuery
    query={graphql`
      query {
        projectReports: file(relativePath: { eq: "project-reports-mock.png" }) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        timesheet: file(relativePath: { eq: "timesheet-mock.png" }) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        collaborators: file(relativePath: { eq: "collaborators-mock.png" }) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        drawings: file(relativePath: { eq: "drawing-log-mock.png" }) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        },
      }
    `}
    render={data => (
      <Fade>
        <section className={styles.container}>
          <h2 className={styles.heading}>Best solution for detailing and engineering teams</h2>
          <Tabs
            namesList={[
              { title: 'Drawing revisions', icon: drawingsIcon },
              { title: 'Collaboration', icon: collaborationIcon },
              { title: 'Time tracking', icon: timetrackingIcon },
              { title: 'Project reports', icon: projectReportsIcon },
            ]}
            contentList={[
              <ImgWithText
                title="Track drawing revisions"
                content1={`Plan submittals and upload drawings. Get Instant access to the latest
                  up-to-date drawings set and check their revisions history`}
                content2={`Simply upload your latest drawings zip file to the scheduled submittal
                  - application will track revisions based on the filenames and allow to filter
                  them by submittals, sequences and revisions`}
                image={data.drawings.childImageSharp.fluid}
                alt="Track drawing revisions"
              />,
              <ImgWithText
                title="Collaborate with other engineers"
                content1={`Share access to your project and latest drawings with your clients or other teams.`}
                content2={`Share latest submittals and revisions. Allow them
                  to comment and upload files or only give access to drawings - you decide.`}
                image={data.collaborators.childImageSharp.fluid}
                alt="Collaborate with other teams"
              />,
              <ImgWithText
                title="Track your team work"
                content1={`Let your team use time tracker to easily track their work, holidays and sick leaves.`}
                content2={`Your team members can report their time spent working on specific submittals or other
                  tasks. They can also report holidays that can be approved or rejected by account admin.`}
                image={data.timesheet.childImageSharp.fluid}
                alt="Tracking your team work"
              />,
              <ImgWithText
                title="Check progress"
                content1={`Check current progress of your team work and react quickly if there are already some delays.`}
                content2={`Based on submittals estimated time and your team work progress the application will show you if there are
                  any bottlenecks and project reestimation or team adjustments needed.`}
                image={data.projectReports.childImageSharp.fluid}
                alt="Project reports"
              />]}
          />
        </section>
      </Fade>
    )}
  />
)

HomeTabs.propTypes = {
}

export default HomeTabs;
