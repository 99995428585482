import PropTypes from 'prop-types';
import React from 'react';
// import Menu from 'react-burger-menu/lib/menus/slide'
import cx from 'classnames';
import styles from "./tabs.module.scss";

class Tabs extends React.PureComponent {
  constructor() {
    super();
    this.state = { activeTab: 0 };
  }

  changeTab = id => {
    this.setState({ activeTab: id })
  };

  render () {
    const { namesList, contentList } = this.props;
    const { activeTab } = this.state;
    return (
      <div className={styles.tabsWrap}>
        <ul className={styles.tabNameWrap}>
          {namesList.map((name, i) =>
          <li
            key={`tab-${i}`}
            onClick={() => this.changeTab(i)}
            className={cx({
              [styles.tabName]: true,
              [styles.activeName]: activeTab === i,
            })}
          >
            <div className={styles.iconWrap}>
              <img src={name.icon} alt={name.title} />
            </div>
            <p className={styles.tabTitle}>
              <span>{name.title}</span>
            </p>
          </li>)}
        </ul>
        <ul className={styles.tabContentWrap}>
          {contentList.map((content, i) =>
            <li
              key={`content-${i}`}
              className={cx({
                [styles.tabContent]: true,
                [styles.activeContent]: activeTab === i,
              })}
            >{content}</li>)}
        </ul>
      </div>
    );
  }
}

Tabs.propTypes = {
  tabsList: PropTypes.array,
}

Tabs.defaultProps = {
  namesList: ['Test 1', 'Test 2'],
  contentList: ['<div>Test 1</div>', '<div>Test 2</div>'],
}

export default Tabs;
