import React from 'react';
import Fade from 'react-reveal/Fade';
import Img from "gatsby-image";
import cx from 'classnames';
import styles from "./imgWithText.module.scss";

const ImgWithText = ({ title, content1, content2, image, alt, isLeftImg = false, isGatsbyImg = true }) => (
  <Fade>
    <div className={cx(styles.tabContentRow, { [styles.imgleftAligned]: isLeftImg })}>
      <div className={styles.tabCol1}>
        <h3>{title}</h3>
        <p className={styles.content1}>{content1}</p>
        {content2 && <div className={styles.content2Wrap}>
          <p className={styles.content2}>{content2}</p>
        </div>}
      </div>
      <div className={styles.tabCol2}>
        {isGatsbyImg ?
          <Img fluid={image} alt={alt} /> :
          <img src={image} alt={alt} />}
      </div>
    </div>
  </Fade>
)

export default ImgWithText;
