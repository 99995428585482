import React, { useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image";
import GetStartedInput from "../../GetStartedInput";
import ConfirmModal from "../../ConfirmModal";
import styles from "./hero.module.scss";

const HeroBanner = () => {
  const [isOpen, toggleOpen] = useState(false);
  return (
    <StaticQuery
      query={graphql`
        query {
          homeHeroBanner: file(relativePath: { eq: "home-hero-banner.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          },
          homeHeroMock1: file(relativePath: { eq: "home-banner-mock1.png" }) {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          },
          homeHeroMock2: file(relativePath: { eq: "home-banner-mock2.png" }) {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      `}
      render={data => (
        <div className={styles.container}>
          <Img alt="Entracker contruction project management" className={styles.heroBanner} fluid={data.homeHeroBanner.childImageSharp.fluid} />
          <section className={styles.innerContainer}>
            <div className={styles.textContainer}>
              <div className={styles.row}>
                <div className={styles.col}>
                  <h1 className={styles.heading}>Best drawing-centric project management solution for engineers</h1>
                  <p className={styles.paragraph}>Project management application designed for engineers and detailers. Get instant access to your projects,
                    download your latest drawings and track their revisions, manage and share your projects with other teams.</p>
                  <GetStartedInput isOpen={isOpen} toggleForm={toggleOpen} />
                </div>
              </div>
            </div>
            <div className={styles.heroMocksWrap}>
              <div className={styles.heroMock1}>
                <Img fluid={data.homeHeroMock1.childImageSharp.fluid} alt="Project management application design for detailing team" />
              </div>
              <div className={styles.heroMock2}>
                <Img fluid={data.homeHeroMock2.childImageSharp.fluid} alt="Best management solution for engineering team" />
              </div>
            </div>
          </section>
          <ConfirmModal isOpen={isOpen} toggleOpen={toggleOpen} />
        </div>
      )}
    />
  )
}

export default HeroBanner
