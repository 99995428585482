import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import ImageRow from '../components/ImageRow';
import SEO from '../components/seo';
import HeroBanner from '../components/HomepageContent/HeroBanner';
import HomeTabs from '../components/HomepageContent/HomeTabs';
import drawingsIcon from '../images/everyone-updated-icon2.svg';
import progressIcon from '../images/progress-icon2.svg';

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query {
        everyoneUpdatedIcon: file(relativePath: { eq: "everyone-updated-icon.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        progressIcon: file(relativePath: { eq: "progress-icon.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        },
      }
    `}
    render={data => (
      <Layout page="home" hasDocsLink>
        <SEO
          title="Project management for engineers and detailers"
          description="Use Entracker in concrete, steel detailers and other engineering teams to easily track team work and drawing revisions"
          keywords={[`project`, `management`, 'civil', `engineer`, 'steel', 'detailer', 'design']}
        />
        <HeroBanner />
        <HomeTabs />
        <ImageRow
          title="Keep everyone updated"
          content={`Simply invite cooperating companies and their users and
            give them access to selected projects. They will be informed by
            an email about updates and issued submittals, receive latest
            revisions and automatically generated transmittal letters.`}
          image={data.everyoneUpdatedIcon.childImageSharp.fluid}
          isGrayBg
          largeIcon
        />
        <ImageRow
          title="Keep track of progress"
          content={`Get detailed reports based on submittals estimates and
            reported worktime. Check if there are any delays before your
            final dates are exceeded. `}
          image={data.progressIcon.childImageSharp.fluid}
          isImgLeft={false}
          largeIcon
        />
      </Layout>
    )}
  />
)

export default IndexPage
